import { matchDates, Week } from "~/lib/utils/date/date-utils";
import {
  FormEntryCard,
  WeekSectionProjectForm,
} from "~/pages/_cmp/weekly-registration-table/form-entry-card";
import { twMerge } from "tailwind-merge";
import { WeeklyProject } from "~/pages/employees/index/weekly";
import Switch from "~/lib/ui/switch";
import Tooltip from "~/lib/ui/tooltip";
import { useTranslation } from "react-i18next";
import { linkToProject } from "~/lib/utils";
import { AuthorizedLink } from "~/lib/utils/routing/authorized-link";
import { useEffect, useMemo, useState } from "react";

type WeekSectionProjectProps = {
  week: Week;
  project: WeeklyProject;
  onFormClick?: (form: WeekSectionProjectForm) => void;
  canApproveForms?: boolean;
  onToggle?: (projectId: string, state: boolean, forms: Array<string>) => void;
};

export function WeekSectionProject({
  week,
  project,
  onFormClick,
  canApproveForms = false,
  onToggle,
}: WeekSectionProjectProps) {
  const { t } = useTranslation();
  const forms = project.forms.filter((form) => !!form.date);

  const toggleState = useMemo(() => {
    return forms.every((form) => !!form.approved);
  }, forms);

  const [localChecked, setLocalChecked] = useState<boolean>(toggleState);

  useEffect(() => {
    setLocalChecked(toggleState);
  }, [toggleState]);

  const handleToggle = (state: boolean, formIds: Array<string>) => {
    setLocalChecked(state);
    onToggle?.(project.id, state, formIds);
  };

  return (
    <tr className="divide-x print:divide-x-2">
      <td className="min-w-72 px-3 py-1.5 align-top text-sm print:min-w-0 print:max-w-20">
        <div className="flex justify-between gap-4">
          <div className="line-clamp-1 font-semibold">
            <AuthorizedLink to={linkToProject(project.id)} openInNewTab={true}>
              {project.name}
            </AuthorizedLink>
          </div>
          {canApproveForms && !!onToggle && (
            <Tooltip
              delay={300}
              trigger={
                <div>
                  <Switch
                    controlled
                    size="small"
                    onCheckedChange={(state) =>
                      handleToggle(
                        state,
                        forms.map((form) => form.id)
                      )
                    }
                    checked={localChecked ?? toggleState}
                  />
                </div>
              }
            >
              {t("forms:approve_all_on_project")}
            </Tooltip>
          )}
        </div>
        <div className="text-nowrap text-sm text-shade-400">
          {!!project.streetName && <div>{project.streetName}</div>}
          {!!project.cityName && !!project.zipCode && (
            <div>{`${project.zipCode} ${project.cityName}`}</div>
          )}
        </div>
      </td>
      {week.days.map((day) => (
        <td
          key={day.date.getTime()}
          className={twMerge(
            "w-24 min-w-24 items-center gap-2 print:w-auto",
            day.isHoliday && "bg-yellow-50",
            day.isWeekend && "bg-shade-200/50"
          )}
        >
          <div className="flex h-full w-full flex-col items-center gap-1 divide-y">
            {forms.map(
              (form) =>
                matchDates(form.date!, day.date, { ignoreTimestamp: true }) && (
                  <FormEntryCard
                    key={form.id}
                    form={form}
                    onClick={() => onFormClick?.(form)}
                    showApprovedState={canApproveForms}
                    onToggle={
                      canApproveForms && !!onToggle
                        ? (d) => handleToggle(d.state, [d.formId])
                        : undefined
                    }
                  />
                )
            )}
          </div>
        </td>
      ))}
    </tr>
  );
}
