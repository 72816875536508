import { createContext, ReactNode, useEffect, useState } from "react";

import { deepEqual } from "~/lib/utils/object/deep-equal";
import {
  MutableOrderLineKeys,
  OrderLine,
  OrderLineAdd,
  OrderLineDiscountType,
  OrderLinesBuilderContextType,
  OrderLinesBuilderOnChangeReturn,
} from "~/lib/ui/order-lines/lib/types";
import { CacheKey, ListResponse } from "~/lib/entity-ui/types";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import {
  addOrderLine,
  repositionOrderLine,
  updateOrderLine,
} from "~/lib/ui/order-lines/lib/mutations";

const OrderLinesBuilderContextDefaults: OrderLinesBuilderContextType = {
  orderLines: [],
  viewOrderLines: [],
  isModified: false,
  isValid: true,
  vat: 0,
  setVat: (value: number) => undefined,
  addLine: () => ({ focusLineId: "", isDuplicate: false }),
  deleteLine: () => undefined,
  updateLine: () => undefined,
  resetInitialValues: () => undefined,
  switchDiscountType: () => undefined,
  repositionLine: () => undefined,
  setViewOrderLines: () => undefined,
  isInitialized: false,
};
export const OrderLinesBuilderContext = createContext<OrderLinesBuilderContextType>(
  OrderLinesBuilderContextDefaults
);

/**
 * Provider for offer lines
 * @param {Offer} offer - The offer to get lines for
 * @param {OfferFormState} formState - Current formState
 * @param {ReactNode} children - The children to render
 */
export default function OrderLinesBuilderProvider<LineType>({
  cacheKey,
  dataFn = async () => ({
    data: [],
    success: true,
  }),
  transformerFn,
  initialVatPercent,
  onChange,
  children,
}: {
  cacheKey?: CacheKey;
  dataFn?: () => Promise<ListResponse<LineType>>;
  transformerFn: (line: LineType, pos: number) => Partial<OrderLine>;
  initialVatPercent?: number;
  onChange?: (data: OrderLinesBuilderOnChangeReturn) => void;
  children: ReactNode;
}) {
  /**
   * Local state
   */

  const [initialLines, setInitialLines] = useState<Array<Partial<OrderLine>>>([
    ...OrderLinesBuilderContextDefaults.orderLines,
  ]);
  const [orderLines, setOrderLines] = useState<Array<Partial<OrderLine>>>(
    OrderLinesBuilderContextDefaults.orderLines
  );

  const [viewOrderLines, setViewOrderLines] = useState<Array<Partial<OrderLine>>>(
    OrderLinesBuilderContextDefaults.orderLines
  );

  const [initialVat, setInitialVat] = useState<number>(initialVatPercent ?? 0);
  const [vat, setVat] = useState<number>(initialVatPercent ?? 0);

  const [isModified, setIsModified] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(true);

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  /**
   * Initialize data
   */
  const queryClient = useQueryClient();
  const { data: dataResponse } = useSuspenseQuery({
    queryKey: cacheKey ?? ["default"],
    queryFn: dataFn,
    refetchOnMount: false,
  });

  const data = dataResponse?.data ?? [];

  const linesAreModified = () => {
    if (orderLines.length !== initialLines.length) return true;
    if (vat !== initialVat) return true;
    return orderLines.some((line) => {
      const initialLine = initialLines.find((l) => l.identifier === line.identifier);
      if (initialLine) {
        return !deepEqual(line, initialLine);
      }
      return true;
    });
  };

  const execTransformLines = (linesToTransform: Array<LineType>) => {
    return linesToTransform.map((line, idx) => {
      return {
        ...line,
        ...transformerFn(line, idx),
      };
    });
  };

  const resetInitialValues = async () => {
    await queryClient.invalidateQueries({ queryKey: cacheKey });
    setInitialLines([...orderLines]);
    setIsModified(false);
  };

  // Set initial values when the data changes (from dataFn resolved or data prop)
  useEffect(() => {
    if (data === undefined) return;
    if (!isInitialized) {
      const transformedLines = execTransformLines(data);
      setInitialLines([...transformedLines].sort((a, b) => (a.position ?? 0) - (b.position ?? 0)));
      setOrderLines([...transformedLines].sort((a, b) => (a.position ?? 0) - (b.position ?? 0)));
      setViewOrderLines(
        [...transformedLines].sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      );
      setIsInitialized(true);
    }
  }, [data]);

  // React to line-changes and call onChange if changed.
  useEffect(() => {
    const linesModified = linesAreModified();
    setIsModified(linesModified);
    setIsValid(orderLines.every((line) => !line.isInvalid));
    onChange?.({ orderLines: orderLines, vatPercent: vat, isInitial: !linesModified });
  }, [orderLines, vat, isModified]);

  /**
   * Methods for manipulating offer lines
   */

  const addLine = (opts: OrderLineAdd): { focusLineId: string; isDuplicate: boolean } => {
    const newOfferLine: Partial<OrderLine> | null = addOrderLine(opts, orderLines.length);

    if (newOfferLine !== null) {
      setOrderLines((prev) =>
        [...prev, { ...newOfferLine }].sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      );
    }

    return { focusLineId: newOfferLine?.identifier ?? "", isDuplicate: false };
  };

  const deleteLine = (id?: string) => {
    if (!id) return;
    setOrderLines((prev) =>
      prev
        .filter((line) => line.identifier !== id)
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
    );
  };

  const repositionLine = (line: Partial<OrderLine>, newPos: number) => {
    setOrderLines(repositionOrderLine({ ...line }, newPos, orderLines));
  };

  const updateLine = (
    keys: Array<MutableOrderLineKeys>,
    values: { [key in MutableOrderLineKeys]?: unknown },
    id?: string,
    parentId?: string | null
  ) => {
    setOrderLines(updateOrderLine(orderLines, keys, values, id, parentId));
  };

  const switchDiscountType = (type: OrderLineDiscountType, line: Partial<OrderLine>) => {
    // Do not mutate state if type is already set
    if (type === "fixed" && line.discountType !== "fixed") {
      updateLine(["discountType"], { discountType: "fixed" }, line.identifier);
    } else if (type === "percent" && line.discountType !== "percent") {
      updateLine(["discountType"], { discountType: "percent" }, line.identifier);
    }
  };

  /**
   * Return provider with values
   */

  return (
    <OrderLinesBuilderContext.Provider
      value={{
        orderLines,
        isModified,
        isValid,
        vat,
        setVat,
        addLine,
        deleteLine,
        updateLine,
        resetInitialValues,
        switchDiscountType,
        repositionLine,
        viewOrderLines,
        setViewOrderLines,
        isInitialized,
      }}
    >
      {children}
    </OrderLinesBuilderContext.Provider>
  );
}
