import { formatCurrency } from "~/lib/utils/number";
import { ChevronDownIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverSide } from "~/lib/ui/popover/popover";
import { useTranslation } from "react-i18next";
import PriceGroupPopover from "~/lib/ui/employee/price-group-popover";
import ProductPopover from "~/lib/products/product-popover";
import ProductBundlePopover from "~/lib/products/product-bundle-popover";
import { OrderLineAdd, OrderLinesActionVisibilityTypes } from "~/lib/ui/order-lines/lib/types";
import { useOrderLinesBuilder } from "~/lib/ui/order-lines/use-order-lines-builder";
import { calculateLinesTotal } from "~/lib/ui/order-lines/lib/calculations";
import { useEffect, useState } from "react";
import { Button } from "~/lib/ui";
import { ButtonSizes } from "~/lib/ui/buttons/shared/button.shared";
import { TabHeaderItem } from "~/lib/ui/tabs/tab-header";
import { Vendor } from "@apacta/sdk";

const defaultOrderLinesFooterActions: OrderLinesActionVisibilityTypes = {
  showAddProduct: true,
  showAddHours: true,
  showAddProductBundle: true,
  showAddText: true,
  showSubtotal: true,
  showVAT: true,
  showTotal: true,
  sumCostPrice: false,
};

export function OrderlinesBuilderTableFooter({
  editMode = false,
  onAddOfferLine,
  companyVatPercent,
  options,
  overwriteSubAmount,
  buttonSizes = "medium",
}: {
  editMode: boolean;
  onAddOfferLine: (opts: OrderLineAdd) => void;
  companyVatPercent?: number;
  options?: {
    orderLinesFooterVisibility?: OrderLinesActionVisibilityTypes;
    popoverOptions?: {
      allowedProductTypes?: Array<string>;
      side?: PopoverSide;
    };
  };
  overwriteSubAmount?: number;
  buttonSizes?: ButtonSizes;
}) {
  const { orderLines, vat, setVat } = useOrderLinesBuilder();
  const { t } = useTranslation();
  const orderLinesOptions = {
    ...defaultOrderLinesFooterActions,
    ...options?.orderLinesFooterVisibility,
  };

  const [subAmount, setSubAmount] = useState<number>(0);
  const [vatAmount, setVatAmount] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);

  const [selectedTab, setSelectedTab] = useState<TabHeaderItem | undefined>();
  const [selectedTabQuery, setSelectedTabQuery] = useState<string | undefined>();
  const [selectedVendor, setSelectedVendor] = useState<Vendor | undefined>();

  useEffect(() => {
    const sAmount = calculateLinesTotal(orderLines, "subAmount", vat);
    if (!overwriteSubAmount || overwriteSubAmount === sAmount) {
      setSubAmount(calculateLinesTotal(orderLines, "subAmount", vat));
      setVatAmount(calculateLinesTotal(orderLines, "vatAmount", vat));
      setTotalAmount(calculateLinesTotal(orderLines, "totalAmount", vat));
    } else {
      const vAmount = overwriteSubAmount * (vat / 100);
      const tAmount = overwriteSubAmount + vAmount;
      setSubAmount(overwriteSubAmount ?? 0);
      setVatAmount(vAmount);
      setTotalAmount(tAmount);
    }

    // TODO: Ask Vladi what this does and why it's setting the total from cost prices
    if (orderLinesOptions.sumCostPrice) {
      const totalCostPriceAmount = calculateLinesTotal(orderLines, "totalCostAmount", 0);
      setTotalAmount(totalCostPriceAmount);
    }
  }, [orderLines, overwriteSubAmount, vat]);

  return (
    <>
      {editMode && (
        <div>
          <div className="pt-8 text-base text-gray-900">
            <div className="flex gap-4">
              {orderLinesOptions.showAddProduct && (
                <ProductPopover
                  defaultTab={selectedTab}
                  defaultQuery={selectedTabQuery}
                  onQueryChange={(query) => setSelectedTabQuery(query)}
                  onVendorChange={(vendor) => setSelectedVendor(vendor)}
                  defaultVendor={selectedVendor}
                  options={options?.popoverOptions}
                  triggerRender={() => (
                    <Button Icon={PlusIcon} variant="secondary" size={buttonSizes}>
                      <span>{t("common:product", { count: 1 })}</span>
                    </Button>
                  )}
                  onTabChange={(tab) => setSelectedTab(tab)}
                  onSelect={(item) => {
                    if (item.type === "product") {
                      onAddOfferLine({ type: item.type, options: { product: item.item } });
                    }
                    if (item.type === "expense_line") {
                      onAddOfferLine({ type: item.type, options: { expenseLine: item.item } });
                    }
                    if (item.type === "vendor_product") {
                      onAddOfferLine({ type: item.type, options: { vendorProduct: item.item } });
                    }
                  }}
                />
              )}
              {orderLinesOptions.showAddHours && (
                <PriceGroupPopover
                  triggerRender={() => (
                    <Button Icon={PlusIcon} variant="secondary" size={buttonSizes}>
                      <span>{t("common:work_hour_usage")}</span>
                    </Button>
                  )}
                  onSelect={(groups) =>
                    onAddOfferLine({ type: "hours", options: { priceGroup: groups[0] } })
                  }
                  options={{ multiselect: false }}
                />
              )}
              {orderLinesOptions.showAddProductBundle && (
                <ProductBundlePopover
                  triggerRender={() => (
                    <Button Icon={PlusIcon} variant="secondary" size={buttonSizes}>
                      <span>{t("common:product_bundle", { count: 1 })}</span>
                    </Button>
                  )}
                  onSelect={(bundles) =>
                    onAddOfferLine({ type: "bundle", options: { bundle: bundles[0] } })
                  }
                  options={{ multiselect: false }}
                />
              )}
              {orderLinesOptions.showAddText && (
                <Button
                  Icon={PlusIcon}
                  variant="secondary"
                  size={buttonSizes}
                  onClick={() => onAddOfferLine({ type: "text" })}
                >
                  <span>{t("common:text")}</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        <div className="select-text pt-8 text-base text-gray-900">
          <div className="flex justify-end pr-3">
            <div className="flex border-b">
              <div className="flex flex-col items-end gap-1">
                {orderLinesOptions.showSubtotal && (
                  <span className="pr-16">{t("finance:subtotal")}</span>
                )}
                {orderLinesOptions.showVAT && (
                  <span className="flex items-center gap-2 pb-2 pr-16">
                    {editMode ? (
                      <>
                        <span>{t("common:vat")}</span>
                        <Popover
                          config={{ align: "start" }}
                          triggerRender={() => (
                            <div className="flex items-center justify-between gap-4 rounded-md border border-gray-300 bg-white px-3 py-1 text-gray-700 shadow-sm transition-colors duration-200 hover:border-hover hover:text-hover">
                              <span>{vat}%</span>
                              <ChevronDownIcon className="h-4 w-4" />
                            </div>
                          )}
                        >
                          {(close) => (
                            <div className="min-w-[10em] rounded-lg border bg-white p-2 text-base shadow-md">
                              <ul>
                                <li
                                  className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
                                  onClick={() => {
                                    setVat(0);
                                    close();
                                  }}
                                >
                                  0%
                                </li>
                                <li
                                  className="cursor-pointer rounded-lg p-2 hover:bg-tertiary hover:text-white"
                                  onClick={() => {
                                    setVat(companyVatPercent ?? 25);
                                    close();
                                  }}
                                >
                                  {`${companyVatPercent ?? 25}%`}
                                </li>
                              </ul>
                            </div>
                          )}
                        </Popover>
                      </>
                    ) : (
                      `${t("common:vat")} (${vat}%)`
                    )}
                  </span>
                )}
              </div>
              <div className="flex flex-col items-end gap-1 pb-2">
                {orderLinesOptions.showSubtotal && <span>{formatCurrency(subAmount)}</span>}
                {orderLinesOptions.showVAT && (
                  <div className="flex-grow-1 flex h-full items-center">
                    {formatCurrency(vatAmount)}
                  </div>
                )}
              </div>
            </div>
          </div>
          {orderLinesOptions.showTotal && (
            <div className="flex justify-end pr-3">
              <div className="flex flex-col items-end gap-1">
                <span className="w-full pr-16 pt-2 text-right font-semibold">
                  {t("finance:total")}
                </span>
              </div>
              <div className="flex flex-col items-end gap-1">
                <span className=" pt-2 font-semibold">{formatCurrency(totalAmount)}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
