import { RowData, Table } from "@tanstack/react-table";
import ViewTabs from "../tabs/view-tabs";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../form-elements";
import { Label } from "../label";

export function TableColumnVisibilityFragment<TData extends RowData>({
  table,
}: {
  table: Table<TData>;
}) {
  const columns = table
    .getAllColumns()
    // Hiding actions makes this component unusable
    .filter((c) => c.columnDef.header !== undefined && c.id !== "actions");
  const { t } = useTranslation();
  return (
    <div className="min-w-64 p-4">
      <ViewTabs
        tabSize="small"
        tabs={[
          {
            id: "columns",
            label: t("common:column", { count: 2 }),
          },
        ]}
      >
        <div className="-mt-5">
          <p className="max-w-sm text-xs text-shade-500">
            {t("ui:table_column_visibility_description")}
          </p>

          <div className="grid grid-cols-2 gap-1">
            {columns.map((column) => {
              const header = column?.columnDef?.header;
              if (typeof header !== "string") return null; // If we can't render it, skip it
              return (
                <div key={column.id} className="flex items-center gap-2">
                  <Checkbox
                    checked={column.getIsVisible()}
                    onChange={() => column.toggleVisibility()}
                    className="mr-1"
                  />
                  <Label>{header}</Label>
                </div>
              );
            })}
          </div>
        </div>
      </ViewTabs>
    </div>
  );
}
