import { twMerge } from "tailwind-merge";
import Switch from "~/lib/ui/switch";
import Tooltip from "~/lib/ui/tooltip";
import FormEntryCardPopover from "~/pages/_cmp/weekly-registration-table/form-entry-card-popover";
import { MouseEvent, useEffect, useState } from "react";
import { useDevice } from "~/lib/utils/device/use-device";
import { useBreakpoints } from "~/lib/utils/tailwind/use-breakpoints";
import { Icon } from "~/lib/ui";
import { padTimeStrings } from "~/lib/utils/date/date-utils";
import { UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner } from "@apacta/sdk";
import { decimalToTimeString } from "~/lib/utils/date/date-utils";
import { useMe } from "~/lib/auth/use-me";

export type WeekSectionProjectForm =
  UsersGetWeekly200ResponseWeeklyViewInnerWeeksInnerProjectsInnerFormsInner;

export function FormEntryCard({
  form,
  onToggle,
  onClick,
  showApprovedState,
}: {
  form: WeekSectionProjectForm;
  onToggle?: ({ formId, state }: { formId: string; state: boolean }) => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  showApprovedState?: boolean;
}) {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [isToggled, setIsToggled] = useState<boolean>(!!form.approved);
  const { isTouchDevice } = useDevice();
  const { isBreakpoint } = useBreakpoints();
  const me = useMe();
  const isApprovalEnabled = me.companySettings.approveForms;

  // useEffect to update the toggled state when the form is changed
  // This is solely to provide a better user experience when the form is approved
  // It avoids waiting for the backend to update the form and then update the UI
  useEffect(() => {
    setIsToggled(!!form.approved);
  }, [form]);

  const totalHours = form.entries.reduce(
    (acc, entry) => acc + (["km", "daily"].includes(entry.timeUnit) ? 0 : entry.total),
    0
  );

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    if (isTouchDevice && !isBreakpoint("sm")) {
      setPopoverOpen(!popoverOpen);
    } else {
      onClick?.(e);
    }
  };

  return (
    <Tooltip
      open={isTouchDevice ? popoverOpen : undefined}
      onOpenChange={isTouchDevice ? setPopoverOpen : undefined}
      side="right"
      delay={100}
      trigger={
        <div
          onClick={handleOnClick}
          className={twMerge(
            "flex select-none flex-col items-center justify-center gap-0.5 px-2 py-1 ",
            "border-gray-300 text-sm font-semibold text-gray-700 hover:bg-shade-50",
            "print:h-auto print:border-0 print:border-l-0 print:border-r-0 print:border-t-0 print:border-b-gray-200"
          )}
        >
          <div className="flex flex-row items-center gap-2">
            {isApprovalEnabled && onToggle && (
              <span className={twMerge("hidden print:inline")}>
                {isToggled ? <Icon name="approve" /> : <Icon name="reject" />}
              </span>
            )}
            {decimalToTimeString(totalHours)}
          </div>
          <div className="text-nowrap text-xs text-gray-400">
            {`${padTimeStrings(form.startTime)} - ${padTimeStrings(form.endTime)}`}
          </div>

          {onToggle && (
            <div>
              <Switch
                className="print:hidden"
                controlled
                size="small"
                onCheckedChange={(state) => {
                  setIsToggled(state);
                  onToggle?.({ formId: form.id, state });
                }}
                checked={isToggled}
              />
            </div>
          )}
        </div>
      }
    >
      <FormEntryCardPopover form={form} showApprovedState={showApprovedState} />
    </Tooltip>
  );
}
