import { To, useLocation, useNavigate } from "react-router";
import { TabHeader, TabHeaderItem } from "~/lib/ui/tabs/tab-header";

export default function PageTabs({
  tabs,
  preserveSearchParams = false,
}: {
  tabs: Array<TabHeaderItem>;
  preserveSearchParams?: boolean;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectTab = (tab: TabHeaderItem) => {
    const to: To = {
      pathname: tab.to,
      search: preserveSearchParams ? location.search : undefined,
    };

    if (to.pathname === undefined) return;

    navigate(to, { replace: true });
  };

  // This isn't bulletproof, but works.
  let selectedIndex = tabs.findIndex((tab) => {
    const to = tab.to;
    if (!to) return false;
    return location.pathname.includes(to);
  });
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }

  return (
    <TabHeader
      selectedTab={tabs[selectedIndex]}
      tabs={tabs}
      onSelect={handleSelectTab}
      className="mb-8 print:hidden"
    />
  );
}
