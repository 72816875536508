import { useOutletContext } from "react-router";
import { Week } from "~/lib/utils/date/date-utils";
import { WeekSection } from "~/pages/_cmp/weekly-registration-table/week-section";
import { useCallback } from "react";
import { WeeklyEmployeeRes, WeeklyProject } from "~/pages/employees/index/weekly";
import { UserAvatar } from "~/lib/ui/avatar";
import { HorizontalDivider } from "~/lib/ui/horizontal-divider";
import { Spinner } from "~/lib/ui/spinner";
import { Icon } from "~/lib/ui";
import PageSection from "~/lib/ui/page-section";
import { useTranslation } from "react-i18next";

export default function OverviewPage() {
  const { t } = useTranslation();

  const { weeks, employee, isFetching } = useOutletContext<{
    weeks: Array<Week>;
    employee: WeeklyEmployeeRes | null;
    isFetching: boolean;
  }>();

  const getWeekProjects = useCallback(
    (week: Week): Array<WeeklyProject> => {
      return (
        employee?.weeks.find((w) => {
          return +w.year === week.year && +w.week === week.weekNumber;
        })?.projects ?? []
      );
    },
    [employee, weeks]
  );

  return (
    <>
      {isFetching ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      ) : !!employee ? (
        <div className="flex flex-col gap-4">
          <HorizontalDivider
            lineClassName="border-t-4 border-shade-600"
            lineLeftWrapperClassName="w-12"
          >
            <div className="flex items-center gap-4">
              <div>
                <UserAvatar user={employee.user} className="h-10 w-10" />
              </div>
              <div className="whitespace-nowrap text-xl">{employee.user.fullName}</div>
            </div>
          </HorizontalDivider>
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-12">
              {weeks.map((week) => {
                const weekProjects = getWeekProjects(week);
                if (weekProjects.length === 0) return null;
                return (
                  <WeekSection
                    key={`week-section-${employee.user.id}-${week.year}-${week.weekNumber}`}
                    week={week}
                    projects={weekProjects}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <PageSection>
          <div className="flex flex-col items-center justify-center gap-1">
            <div>
              <Icon name="infoCircle" className="h-18 w-18" />{" "}
            </div>
            <div className="flex flex-col items-center">
              <div className="font-medium">{t("users:weekly.no_registrations_title")}</div>
              <div className="whitespace-pre-line text-center text-sm">
                {t("forms:no_registrations_user")}
              </div>
            </div>
          </div>
        </PageSection>
      )}
    </>
  );
}
